import { ref } from 'vue';
import axios from '@/services/useAxios';

export default function () {
    const motivoconsulta = ref(null);
    const motivosconsultas = ref([]);

    const getMotivosconsulta = async (id) => {
        const res = await axios.get(`/care/motivo-consulta/${id}`);

        if (res.status === 200) {
            motivoconsulta.value = res.data;
        }
    };

    const getMotivosconsultas = async (searchQuery = '') => {
        const query = searchQuery ? `?searchQuery=${searchQuery}` : '';

        const res = await axios.get(`/care/motivo-consulta${query}`);

        if (res.status === 200) {
            motivosconsultas.value = res.data.items;
        }
    };

    const createMotivosconsulta = async (data) => {
        const res = await axios.post('/care/motivo-consulta', data);
        if (res.status === 201) {
            // console.log(res.data);
            motivoconsulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const updateMotivosconsulta = async (id, data) => {
        const res = await axios.put(`/care/motivo-consulta/${id}`, data);
        if (res.status === 200) {
            // console.log(res.data);
            motivoconsulta.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };

    return {
        motivoconsulta,
        motivosconsultas,
        getMotivosconsulta,
        getMotivosconsultas,
        createMotivosconsulta,
        updateMotivosconsulta,
    };
}
